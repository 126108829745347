import React from 'react'
import {
  DatePickerInput as MantineDatePicker,
  DatePickerInputProps,
  DatePickerType,
  DatesRangeValue,
} from '@mantine/dates'

import { MaterialIcon } from '@/core/components/material-icon'

import styles from './styles.module.scss'

export const DatePickerInput = <T extends DatePickerType = 'default'>({
  classNames,
  clearable = true,
  ...props
}: DatePickerInputProps<T>) => {
  return (
    <MantineDatePicker
      classNames={{
        input: styles.input,
        day: styles.day,
        ...classNames,
      }}
      clearable={clearable}
      placeholder={
        props.type === 'range' ? 'MM/DD/YYYY - MM/DD/YYYY' : 'MM/DD/YYYY'
      }
      valueFormat="MM/DD/YYYY"
      leftSection={<MaterialIcon icon={'event'} />}
      firstDayOfWeek={0}
      {...props}
    />
  )
}

export type { DatesRangeValue }
