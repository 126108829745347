import React from 'react'
import { Menu as MantineMenu, MenuProps } from '@mantine/core'

import styles from './Menu.module.scss'

export const Menu = ({ ...props }: MenuProps) => {
  return (
    <MantineMenu
      shadow="md"
      classNames={{
        dropdown: styles.dropdown,
      }}
      {...props}
    />
  )
}

Menu.Item = MantineMenu.Item
Menu.Label = MantineMenu.Label
Menu.Target = MantineMenu.Target
Menu.Divider = MantineMenu.Divider
Menu.Dropdown = MantineMenu.Dropdown
