/**
 * This module is used to initialize global services that must always be
 * initialized before other application modules.
 */
import { pdfjs } from 'react-pdf'

import { config } from '@/core/config'

import { ErrorMonitoring } from './services/error-monitoring'

if (config.enableErrorReporting) {
  ErrorMonitoring.init()
}

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString()
