import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Avatar as MantineAvatar,
  AvatarProps as MantineAvatarProps,
} from '@mantine/core'

import styles from './Avatar.module.scss'

export interface AvatarProps extends MantineAvatarProps {
  initials: string
  fullName: string
  avatarUrl?: string
  className?: string
  alt?: string
}

export const Avatar: React.FC<AvatarProps> = ({
  initials,
  fullName,
  avatarUrl,
  alt,
  ...props
}) => {
  const { t } = useTranslation('common')

  const avatarPlaceholder =
    alt ||
    t('Avatar image for {{fullName}}', {
      ns: 'common',
      fullName,
    })

  if (avatarUrl) {
    return (
      <MantineAvatar
        src={avatarUrl}
        classNames={{
          root: styles.root,
          placeholder: styles.placeholder,
        }}
        alt={avatarPlaceholder}
        {...props}
      />
    )
  }

  return (
    <MantineAvatar
      classNames={{
        root: styles.root,
        placeholder: styles.placeholder,
      }}
      alt={avatarPlaceholder}
      {...props}
    >
      {initials.toUpperCase()}
    </MantineAvatar>
  )
}
